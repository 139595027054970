import "../styles/Landon.css";
import landonZilla from "../bin/images/landonzilla.png";

export default function Home() {
  return (
    <div id="landonZilla">
      <img src={landonZilla} alt="LandonZilla" />
    </div>
  );
}
